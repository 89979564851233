import React from "react";
import * as styles from "../styles/navbar.module.css";
import logo from "../images/divcite-no-shadow.png";
import logo2 from "../images/logo-no-underline.png";

const Navbar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <img src={logo2} alt="divcite-logo" />
      </div>
    </div>
  );
};

export default Navbar;
