import React from "react";
import * as styles from "../styles/footer.module.css";

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.footerWrapper}>
        <a href="mailto:contact@divcite.com" target="_blank" rel="noreferrer">
          Contact us
        </a>
      </div>
    </div>
  );
};

export default Footer;
