import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import * as styles from "../styles/index.module.css";
import logo from "../images/divcite-no-shadow.png";

const Index = () => {
  const [opacity, setOpacity] = useState("");
  const [isScrolledBottom, setIsScrolledBottom] = useState(false);

  React.useEffect(() => {
    const isBrowser = typeof window !== "undefined";
    if (!isBrowser) return;
    const handleScroll = () => {
      window.onscroll = function () {
        var totalPageHeight = document.body.scrollHeight;
        var scrollPoint = window.scrollY + window.innerHeight;
        if (scrollPoint >= totalPageHeight) {
          setIsScrolledBottom(true);
        } else {
          setIsScrolledBottom(false);
        }
      };
      const elem = document.documentElement;
      setOpacity(1 - (elem.scrollTop / elem.clientHeight) * 2);
    };
    document.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={styles.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Divcite</title>
        <link rel="canonical" href="https://divcite.com" />
      </Helmet>
      <Layout>
        <main>
          <div className={styles.textWrapper}>
            {isScrolledBottom ? <div className={styles.imageSwitch}></div> : ""}
            <cite style={{ opacity: opacity }}>Not a diving company</cite>
          </div>
        </main>
        <div className={styles.aboutContainer}>
          <div className={styles.aboutWrapper}>
            <div>
              <img src={logo} alt="divcite-logo" />
            </div>
            <p>
              ...unless you mean diving into quality code? Our vision is to have
              an ocean full of user friendly web apps.
            </p>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Index;
