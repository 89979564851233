import React from "react";
import Footer from "./Footer";
import Navbar from "./Navbar";
import "../styles/global.css";
import * as styles from "../styles/layout.module.css";

const Layout = ({ children }) => {
  return (
    <div className={styles.container}>
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
